import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  id=\"editor\"\n  class=\"mx-auto w-full h-screen\"\n  style=\"max-height:700px;\"\n  {{did-insert this.initImageEditor}}\n  {{will-destroy this.disposeEditor}}\n>\n</div>\n{{yield (hash saveImage=this.saveImage restoreImage=this.doRestoreState)}}", {"contents":"<div\n  id=\"editor\"\n  class=\"mx-auto w-full h-screen\"\n  style=\"max-height:700px;\"\n  {{did-insert this.initImageEditor}}\n  {{will-destroy this.disposeEditor}}\n>\n</div>\n{{yield (hash saveImage=this.saveImage restoreImage=this.doRestoreState)}}","moduleName":"weldnote/components/schematic-image-editor5.hbs","parseOptions":{"srcName":"weldnote/components/schematic-image-editor5.hbs"}});
import { action } from '@ember/object';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { IntlService } from 'ember-intl';
import { AdvancedStickerControlBarItem, EditorApi, ExportFormat, ImageFormat, PhotoEditorSDKUI, StickerCategory, Tool, UIEvent } from 'photoeditorsdk';
import { hash } from 'rsvp';
// import { hash } from 'rsvp';
import LoadingService from 'weldnote/services/loading';

interface SchematicImageEditor5Args {
  Args: {
    editorType: string;
    imagePath: string;
    state: string;
    language: string;
    onSave: (value: any) => {};
    onClose: () => {};
    categories: string[];
  }
}

type Color = [number, number, number, number];
interface ColorPalette {
  color: Color;
  name: string;
}



const license =
      '{"api_token":"-A3BpLqyV_OceWs0kxSa9w","app_identifiers":["*.weldnote.com","localhost","*.indusuite.com","127.0.0.1"],"available_actions":[],"domains":["https://api.photoeditorsdk.com"],"enterprise_license":false,"expires_at":null,"features":["camera","library","export","customassets","whitelabel","adjustment","sticker","text"],"issued_at":1727271741,"minimum_sdk_version":"1.0","owner":"ESAB, Lda.","platform":"HTML5","products":["pesdk"],"version":"2.4","signature":"cjY3aNKHJzEwCTlEUGMj1Kjt42eydJrIsnh3+vdqXGLfsRb3zlv0m43b1s10uXvRzlRw7h3Dk6g6vzmN9IPKM83v22N0EmyXuhMK1ze1lJq7z4HUKVZHHBWHbxxxtJq7t2kHCirwqKUy7uvdy25BSfTBLkDV6JThJYmf72jmp4MXHN7bf78Z68Fq/RbBGy+8cHADp/OsYmNiV9hR4ugwQI68bkKgBO+dkmZNxkAXhLdlgqKTEyTKlrzWTwSmOpzNvGh3VWbNPCH41rXIRt0sqs7LVaRkgLv0q7kZadJnuupqkKW9ncGjZgOykJqPVDYXCtewthKwSWuunJepFQQK9bTNdXYLQgm0hFEecd/JWADkflc1OZ4iLm7k1vA7Llp6kJyqYURG/iSetx483TqMpDjxSP7zSXa6NXRoRk+av8cfM0bapzHATiaxGUnz13+zZxtjYkhBnDmtWCIN8Fo7nmLaw+dZqglDTvTUHnh4WvLnRTJPAUa8ENLyzsmI9uWyiu6o9ifahXZIjL8wDQ2LuA/9fQax8zE3kk4DABmxEBjYGuT6EAJlEU2EZ6IYVSIdcJO7Tqk7XTXHggD89GcEqiONtCdhYSvSEP8B+SH3HcE0rnj2ccDUhtGlAN7u6pNz0v1IKwyTxvHPWZjhqWgJAU03uhSNnqEDrCWA2XH2kbM="}';

const textColorPalette: ColorPalette[] = [
  { color: [255, 255, 255, 1.0], name: "Black" },
  { color: [255, 0, 0, 1.0], name: "Red" },
  { color: [0, 255, 0, 1.0], name: "Green" },
  { color: [0, 0, 255, 1.0], name: "Blue" },
  { color: [0, 0, 0, 1.0], name: "White" },
];

interface Category {
  name: string;
  tintMode: "solid" | "none";
}

const categories: Category[] = [
  { name: "arrows", tintMode: "solid" },
  { name: "dimensions", tintMode: "solid" },
  { name: "backing", tintMode: "none" },
  // These are hidden at the moment, only to be shown in Notes?
  { name: "base-plates-offcenter", tintMode: "none" },
  { name: "base-plates-standard", tintMode: "none" },
  { name: "passes", tintMode: "none" },
];

interface CategoryValues {
  [key: string]: string[];
}

const categoryValues: CategoryValues= {
  arrows: [
    "arrowleft",
    "arrowright",
    "arrowshorizontal",
    "arrowdown",
    "arrowup",
    "arrowsvertical",
  ],
  dimensions: [
    "anglehalfleft",
    "anglehalfright",
    "arrowlinedown",
    "arrowlineleft",
    "arrowlineright",
    "arrowlineup",
    "dimensiondiagonalinside",
    "dimensiondiagonaloutside",
    "dimensionhorizontalinside",
    "dimensionhorizontaloutside",
  ],
  "base-plates-offcenter": [
    "double-J-groove-right-down",
    "double-J-groove-right-up",
    "double-V-groove-right-down",
    "double-V-groove-right-up",
    "V-double-groove-right-down",
    "V-double-groove-right-up",
  ],
  "base-plates-standard": [
    "double-J-groove-right-center",
    "double-V-groove-right-center",
    "J-groove-right",
    "square-groove-right",
    "V-double-groove-right-center",
    "V-double-groove",
    "V-groove-right",
  ],
  backing: ["circle", "rectangle"],
  passes: [
    "welding-pass-fillet-concave",
    "welding-pass-fillet-convex",
    "welding-pass-normal-3P",
    "welding-pass-normal-6P",
    "welding-pass-normal-10P",
    "welding-pass-normal",
    "welding-pass-thin",
    "welding-pass-wide",
  ],
};

export default class SchematicImageEditor5 extends Component<SchematicImageEditor5Args> {

  @service
  declare loading: LoadingService;

  @service
  declare intl: IntlService;

  @tracked
  editor: EditorApi | null = null;
  

  get editorType() {
    return this.args.editorType || 'grooveDesign';
  }

  constructor(owner: unknown, args: SchematicImageEditor5Args['Args']) {
    super(owner, args);
  }

  loadCategories(): StickerCategory[] {
    let result:StickerCategory[] = [];
    categories.forEach((category) => {
      if (this.editorType === "grooveDesign") {
        const grooveCategories = ["arrows", "dimensions", "backing"];
        if (grooveCategories.indexOf(category.name) > -1) {
          result.push(this.getCategoryIcons(category));
        }
      } else if (this.editorType === "weldLayerConfiguration") {
        const weldLayerCategories = [
          "arrows",
          "dimensions",
          "backing",
          "passes",
        ];
        if (weldLayerCategories.indexOf(category.name) > -1) {
          result.push(this.getCategoryIcons(category));
        }
      } else {
        result.push(this.getCategoryIcons(category));
      }
    });
    return result;
  };

  getCategoryIcons(category: Category): StickerCategory {
    let categoryElements = categoryValues[category.name];
    return {
      identifier: category.name,
      name: category.name,
      thumbnailURI: `${category.name}/background.png`,
      items: categoryElements.map((element) => {
        return {
          identifier: `${element}`,
          name: `${element}`,
          resizeMode: "unrestricted",
          tintMode: category.tintMode,
          thumbnailURI: `${category.name}/stickers/${element}_thumb.png`,
          stickerURI: `${category.name}/stickers/${element}.png`,
        };
      }),
    };
  };

  get language() {
    let currentLanguage = this.args.language;
    if (currentLanguage) {
      if (currentLanguage.indexOf('en') > -1) {
        return 'en';
      } else if (currentLanguage.indexOf('pt') > -1) {
        return 'pt';
      }
    }
    return 'en';
  }
      

  async initEditor(imagePath: string) {
    this.editor = await PhotoEditorSDKUI.init({
      container: "#editor",
      license,
      image: imagePath,
      language: this.language,
      tools: [Tool.TEXT, Tool.STICKER],
      defaultTool: Tool.STICKER,
      layout: "advanced",
      theme: "dark",
      assetBaseUrl: "/photoeditor5",
      text: {
        colors: textColorPalette,
        backgroundColors: [],
        defaultColor: [0, 0, 0, 1],
      },
      sticker: {
        categories: this.loadCategories(),
        enableCustomUpload: false, // Prevent users from uploading their own stickers
        defaultTintColor: [0, 0, 0, 1], // This is here so that when we add a sticker of tintMode solid it will have this color (dimensions and arrows)
        advancedUIToolControlBarOrder: [
          AdvancedStickerControlBarItem.Items,
          AdvancedStickerControlBarItem.Separator,
          AdvancedStickerControlBarItem.StickerOpacitySlider,
          AdvancedStickerControlBarItem.StickerColorList
        ],
      },
      export: {
        image: {
          enableDownload: false,
          format: ImageFormat.PNG,
          exportType: ExportFormat.DATA_URL,
        },
      },
      custom: {
        languages: {
          en: {
            sticker: {
              title: "Joint Preparation",
              controls: {
                itemAriaLabel: "Add ${label} joint",
              },
            },
            infoModals: {
              stickerLoading: {
                heading: "Loading Joint...",
              },
            },
            errorModals: {
              stickerLoading: {
                body: "Unable to load the joint",
              },
            },
          },
          pt: {
            common: {
              error: "Erro",
              warning: "Aviso",
              color: {
                colorPicker: {
                  hex: "Hex",
                  r: "R",
                  g: "G",
                  b: "B",
                  sliderHue: "Cor",
                  sliderOpacity: "Transparência da Cor",
                },
              },
            },
            mainCanvasActions: {
              buttonExport: "Salvar Esquema",
              buttonUndo: "Desfazer",
              buttonRedo: "Refazer",
              buttonClose: "Fechar",
            },
            infoModals: {
              exporting: {
                heading: "A salvar...",
                body: "Por favor espere...",
              },
              saving: {
                heading: "A guardar...",
                body: "Por favor espere...",
              },
              loading: {
                heading: "A Carregar...",
                body: "Por favor espere...",
              },
              resizing: {
                heading: "A redimensionar...",
                body: "Por favor espere...",
              },
              loadingFonts: {
                heading: "A carregar fontes...",
                body: "Por favor espere...",
              },
              stickerLoading: {
                heading: "A carregar juntas...",
                body: "Por favor espere...",
              },
            },
            errorModals: {
              imageLoading: {
                headingDelimiter: ":",
                body: "Carregamento da Imagem falhou. Isto pode ter vários motivos como a imagem estar corrompida ou o formato não ser suportado",
                buttonYes: "Tentar novamente",
                buttonNo: "Fechar",
              },
              rendering: {
                headingDelimiter: ":",
                body: "Ocorreu um erro a gerar a imagem",
                buttonYes: "Tentar novamente",
                buttonNo: "Fechar",
              },
              fontLoading: {
                headingDelimiter: ":",
                heading: "Carrgamento da fonte falhou",
                body: "As seguintes fontes não foram carregadas: ${error}",
                buttonNo: "Fechar",
              },
              stickerLoading: {
                headingDelimiter: ":",
                body: "Não foi possível carregar a junta",
                buttonNo: "Fechar",
              },
              unexpectedError: {
                headingDelimiter: ":",
                body: "O seguinte erro desconhecido ocorreu - ${error}",
                buttonYes: "Tentar novamente",
                buttonNo: "Fechar",
              },
            },
            warningModals: {
              imageResized: {
                headingDelimiter: ":",
                heading: "Image resized",
                body: "Your image exceeds the maximum size of ${megapixels} megapixels and has been resized to ${width}x${height} pixels",
                buttonYes: "Continuar",
              },
              unsupportedSerializationVersion: {
                headingDelimiter: ":",
                body: "Os seus dados foram restaurados a partir de uma versão antiga e podem ter um aspecto diferente",
                buttonYes: "Aplicar alterações",
                buttonNo: "Cancelar",
              },
              discardChanges: {
                headingDelimiter: ":",
                body: "Tem alterações por guardar. Tem a certeza que as quer descartar?",
                buttonYes: "Descartar alterações",
                buttonNo: "Manter alterações",
              },
              unsavedChanges: {
                headingDelimiter: ":",
                body: "Tem alterações por guardar. Tem a certeza que quer sair?",
                buttonYes: "Sair sem gravar",
                buttonNo: "Cancelar",
              },
              unsupportedWebGLRenderer: {
                body: "A problem has been detected in the browser that could cause long loading times. Please try a different browser.",
                buttonYes: "Continuar",
              },
            },
            sticker: {
              title: "Juntas",
              controls: {
                sliderOpacity: "Transparência da Junta",
                selectColor: "Cor da Junta",
                tabColor: "Cor",
                tabOpacity: "Transparência",
                itemAriaLabel: "Adicionar junta ${label}",
              },
              categories: {
                imgly_sticker_emoticons: "Emoticons",
                imgly_sticker_shapes: "Shapes",
                imgly_sticker_custom: "Custom",
              },
              canvasActions: {
                buttonDelete: "Apagar",
                buttonBringToFront: "Trazer para a frente",
                buttonDuplicate: "Duplicar",
                buttonFlipHorizontal: "Virar",
                buttonFlipVertical: "Virar",
              },
            },
            text: {
              title: "Texto",
              controls: {
                buttonNew: "Novo Texto",
                dropdownFontFamily: "Fonte",
                textFontSize: "Tamanho",
                selectAlignment: "Alinhamento",
                selectFontColor: "Cor",
                selectBackgroundColor: "Cor de Fundo",
                sliderLineSpacing: "Espaco entre linhas",
                tabColor: "Cor",
                tabBgColor: "Color F.",
                tabAlignment: "Alinhamento",
                tabLineHeight: "Espaco entre linhas",
                tabFontSize: "Tamanho",
              },
              canvasControls: {
                placeholderText: "Escreva algo",
                buttonSave: "Feito",
                buttonClose: "Cancelar",
                inputText: "O seu texto",
              },
              canvasActions: {
                buttonEdit: "Alterar",
                buttonDelete: "Apagar",
                buttonBringToFront: "Mudar para a frente",
                buttonDuplicate: "Duplicar",
              },
            },
          },
        },
      },
    });
    this.restoreState(this.args.state);
    this.editor.on(UIEvent.CLOSE, () => {
      if (this.args.onClose) {
        this.args.onClose();
      }
    });
  }

  @action
  initImageEditor(element: HTMLElement) {
    if (element) {
      
    }
    // let { loading } = this;
    let imagePath = this.args.imagePath;
    let image = new Image();
    // loading.block();
    image.onload = () => {
      this.initEditor(imagePath);
      
    };
    image.onerror = () => {
      window.alert(this.intl.t('components.schematic-image-editor.messages.error-loading'));
      // loading.unblock();
    };
    // Required because the S3 Images have cross-origin headers
    image.crossOrigin = 'anonymous';
    image.src = imagePath;
    // image.src = imagePath;
  }

  @action
  disposeEditor() {
    if (this.editor) {
      this.editor.dispose();
    }
  }

  @action
  saveImage() {
    let { editor } = this;
    if (editor) {
      hash({
        state: editor.serialize({ image: false }),
        image: editor.export({ exportType: ExportFormat.DATA_URL }),
      }).then(({ state, image }) => {
        if (typeof image === 'string') {
          let imageBase64 = image.split(',')[1];
          this.args.onSave({
            state,
            image: imageBase64,
          });
        }
      });
    }
  }

  restoreState(state: string) {
    let { loading } = this;
    if (!isEmpty(state) && this.editor) {
      // Shouldn't need this, but haven't figured out where sometimes
      // the state comes transformed as string and sometimes as JSON Object
      let jsonState = typeof state === 'string' ? JSON.parse(state) : state;
      this.editor.on(UIEvent.EDITOR_READY, () => {
        if (this.editor) {
          this.editor
            .deserialize(jsonState)
            .then(() => {
              // loading.unblock();
            })
            .catch(() => {
              // loading.unblock();
            });
        }
      });
    } else {
      loading.unblock();
    }
  }

  @action
  doRestoreState(state: string) {
    this.restoreState(state);
  }

}
